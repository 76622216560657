import { BaseSvgProps } from "./types";

const SvgDownload = ({
  width,
  height,
  stroke = "#000",
  className,
  strokeWidth = 1.5,
}: BaseSvgProps) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    width={width || 24}
    height={height || 24}
    className={className}
  >
    <path
      d="M1.598 17.601v1.6a3.2 3.2 0 0 0 3.2 3.201h14.404a3.2 3.2 0 0 0 3.2-3.2v-1.6M7.2 10.4 12 16m0 0 4.801-5.6M12 16V1.599"
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgDownload;
