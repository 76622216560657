"use client";

import {
  Button,
  ChevronArrowDown,
  Download,
  Select,
  Typography,
} from "@submix/component-library";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import HeroImage from "../../public/macbook-pro-screen-session-dashboard.png";
import Artwork from "../../public/Artwork.png";

const greenLink = `${Button.classes.baseClassName} ${Button.classes.classNames.primary} ${Button.classes.classNames.primary_green}`;

type HeroProps = {
  H1Content: React.ReactNode;
  descriptionContent: React.ReactNode;
  downloadOptions?: {
    name: string;
    id: string;
  }[];
  downloadButtonText?: string;
  detectingOs: React.ReactNode;
};

const Hero: React.FC<HeroProps> = ({
  H1Content,
  downloadButtonText,
  downloadOptions,
  descriptionContent,
  detectingOs,
}) => {
  const [downloadOption, setDownloadOption] = React.useState(
    downloadOptions && downloadOptions.length > 0
      ? downloadOptions[0]
      : { id: "", name: "" },
  );

  return (
    <section className=" bg-neutral-900">
      <div id="hero" className="relative pb-9 pt-24">
        <div className="inset-0 m-auto grid max-w-[1440px] grid-cols-1 items-center gap-12 text-left max-md:px-5 md:grid-cols-12 md:gap-7">
          <div className="md:col-span-5 md:col-start-2">
            <Typography.Body
              onDark
              className="!font-headings !text-4xl !font-bold max-[350px]:!text-4xl lg:!text-4xl"
            >
              {H1Content}
            </Typography.Body>
            <Typography.Body
              onDark
              className="py-7 !leading-6 tracking-[0.02em] lg:text-lg"
            >
              {descriptionContent}
            </Typography.Body>
            {downloadOptions && downloadOptions.length > 0 ? (
              <div className="max-w-fit pb-7">
                {downloadOptions.length > 1 ? (
                  <Select
                    className="mb-2.5 max-w-80 pr-8"
                    label=""
                    options={downloadOptions}
                    onSelect={(value) => setDownloadOption(value)}
                    selected={downloadOption}
                    buttonClassNames="bg-transparent text-neutral-50 !border-neutral-50 border !rounded-[4px]"
                    buttonIcon={
                      <ChevronArrowDown
                        className="h-5 w-5 text-neutral-50"
                        stroke="#fff"
                      />
                    }
                  />
                ) : null}
                <Link
                  prefetch={false}
                  className={`${greenLink} inline-flex px-7 py-4 !text-base text-neutral-900`}
                  target="_blank"
                  href={
                    downloadOptions.length >= 2
                      ? downloadOption?.id || "/"
                      : downloadOptions[0]?.id || "/"
                  }
                >
                  <span className="mr-2.5">
                    <Download
                      height={16}
                      width={16}
                      strokeWidth={2}
                      className="min-w-4"
                    />
                  </span>
                  {downloadButtonText}
                </Link>
              </div>
            ) : null}
            <Typography.Body
              onDark
              element="div"
              className="font-normal lg:text-xl"
            >
              {detectingOs}
            </Typography.Body>
          </div>
          <div className="pl-5 pt-20 md:col-span-5 md:col-start-7 md:pt-10">
            <div className="relative bottom-20 right-5 h-0">
              <div
                style={{
                  background:
                    "radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%)",
                }}
                className="h-96 w-96 px-10 opacity-15 md:h-80 md:w-80"
              />
            </div>
            <div className="relative bottom-20 right-5 h-0">
              <Image src={Artwork} width={150} priority alt={""} />
            </div>
            <div>
              <Image
                src={HeroImage}
                width={500}
                priority
                alt={""}
                className="max-w-fit md:max-w-full"
                sizes="(max-width: 1024px) 100vw, 750px"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
